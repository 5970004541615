// Admin - Cards

// Batch
export const BATCH_FORMAT_DATA = 'batch_format_data';
export const BATCH_FORMAT_NOTIFICATION = 'batch_format_notification';
export const BATCH_CARD_NOTIFICATION = 'batch_card_notification';
export const BATCH_DETAILS_DATA = 'batch_summary_data';
export const PIN_BATCH_STATUS = 'pin_batch_status';
export const MANAGE_PIN_REMOVE = 'manage_pin_remove';
export const BATCH_PIN_NOTIFICATION = 'batch_pin_notification';
export const GET_TARIFF = 'get_tariff';
export const TARIFF_PLAN_TYPE = 'tariff_plan_type';
export const TARIFF_PLAN_GROUP = 'tariff_plan_group';

//Configuration
export const CHECK_CONFIG_LOGIN = 'check_config_login';
export const GET_CUSTOMERS = 'get_customers';
export const GET_CUSTOMER_CONFIG = 'get_customer_config';
export const SAVE_CONFIG = 'save_config';
export const GET_CREDIT_HISTORY = 'get_credit_history';
export const GET_CREDIT_HISTORY_SORTED = 'get_credit_history_sorted';
export const INSERT_CREDITS = 'insert_credits';
export const GET_INCHARGE_LIST = 'get_incharge_list';
export const LOGIN_NOTIFICATION = 'login_notification';
export const CHANGE_PASSWORD = 'change_password';
export const CHECK_CONFIGURATION = 'check_configuration';
export const GET_ADMIN_USER = 'get_admin_user';
export const GET_ADMIN_USER_1 = 'get_admin_user_1';
export const GET_ADMIN_USER_LIST = 'get_admin_user_List';

/*End User Details */
export const GET_END_USER = 'get_end_user';
export const GET_UNIQUE_END_USER = 'get_unique_end_user';
export const UPDATE_END_USER = 'update_end_user';
export const SELECTED_END_USER_LOG = 'selected_end_user_log';
export const END_USER_LOG_COUNT = 'end_user_log_count';
export const CLEAR_USER_LOG = 'clear_user_log';

//card search
export const GET_CARD_SEARCH = 'get_card_search';

/*Get bulk pin download */
export const GET_BULKPIN_DOWNLOAD = 'get_bulk_pin_download';
export const GET_BULKPIN_EXCEL = 'get_bulk_pin_excel';
export const SET_DISTRIBUTOR_PINS='SET_DISTRIBUTOR_PINS';
//ticket price
export const GET_TICKET_PRICE = 'get_ticket_price';
export const UPDATE_TICKET_PRICE = 'update_ticket_price';
export const DELETE_TICKET_PRICE = 'delete_ticket_price';

/* Banner Management */

export const INSERT_BANNER = 'insert_banner';
export const UPDATE_BANNER = 'update_banner';
export const GET_BANNER = 'get_banner';
export const DELETE_BANNER = 'delete_banner';

/* User Menu Management */

export const GET_ALL_MENUS = 'get_all_menus';
export const INSERT_USER_MENU = 'insert_user_menu';
export const UPDATE_USER_MENU = 'update_user_menu';
export const GET_USER_MENU = 'get_user_menu';
export const DELETE_USER_MENU = 'delete_user_menu';
export const DELETE_USER_LIST = 'delete_user_list';

/* User Work Group Management */
export const INSERT_WORKGROUP = 'insert_workgroup';
export const UPDATE_WORKGROUP = 'update_workgroup';
export const GET_WORKGROUP = 'get_workgroup';
export const DELETE_WORKGROUP = 'delete_workgroup';

export const INSERT_USER_WORKGROUP = 'insert_user_workgroup';
export const UPDATE_USER_WORKGROUP = 'update_user_workgroup';
export const GET_USER_WORKGROUP = 'get_user_workgroup';
export const DELETE_USER_WORKGROUP = 'delete_user_workgroup';

/* User Work Group Management */
export const INSERT_WORKGROUP_MENU = 'insert_workgroup_menu';
export const UPDATE_WORKGROUP_MENU = 'update_workgroup_menu';
export const GET_WORKGROUP_MENU = 'get_workgroup_menu';
export const DELETE_WORKGROUP_MENU = 'delete_workgroup_menu';
export const GET_WORKGROUP_MENU_LIST = 'get_workgroup_menu_list';

//Tariff Plan Mapping
export const GET_VOUHCER_CONFIG = 'get_voucher_config';
/* User Activation List */

export const INACTIVE_USER = 'inactive_user';
export const ACTIVATED_USER = 'activated_user';
export const DELETED_INACTIVATE_USER = 'deleted_activated_user';

//Invoice Generation
export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const GET_ADMIN_INVOICE_REPORT = 'get_admin_invoice_report';
export const DELETE_INVOICE_LIST = 'DELETE_INVOICE_LIST';
export const POST_INVOICE_LIST = 'POST_INVOICE_LIST';

//sim Inventory
export const GET_SIM_STOCK = 'get_sim_stock';
export const GET_USER_SIM_STOCK = 'get_user_sim_stock';
export const MANAGE_SIM_STOCK = 'manage_sim_stock';
export const GET_SIM_INVENTORY = 'get_sim_inventory';
export const MANAGE_SIM_INVENTORY = 'manage_sim_stock';
export const GET_USER_TYPES = 'get_user_types';
export const CHANGE_SIM_STATUS = 'change_sim_status';
export const GET_ICCID = 'get_iccid';
export const REVERT_SIM_STATUS = 'revert_sim_status';
export const GET_SIM_DISTRIBUTION = 'get_sim_distribution';
export const GET_SIM_USER_DISTRIBUTION = 'get_sim_user_distribution';
export const STORE_XML_FILE = 'store_xml_file';
export const INSERT_PHONE_NUMBER = 'insert_phone_number';
export const GET_PHONE_NUMBER = 'get_phone_number';
export const GET_MSISDN_LIST = 'get_msisdn_list';
export const GET_MSISDN_UPLOAD_LIST = 'get_msisdn_upload_list';

//Registered User
export const GET_REGISTEREDUSER = 'get_registereduser';
export const GET_DSPUSER = 'get_dspuser';
export const USER_NOTIFICATION = 'user_notification';
export const REG_USER = 'reg_user';
export const SUB_ADMIN_REGISTER = 'sub_admin_register';
export const UPADTE_ADMIN_USER = 'update_admin_user';

export const GET_DSP_USER = 'get_dsp_user';
export const GET_DSP_USER_LIST = 'get_dsp_user_list';
export const GET_DSP_USER_1 = 'get_dsp_user_1';
export const GET_DSP_USER_RETAILER = 'get_dsp_user_retailer';
export const DELETE_PROFILE = 'delete_profile';
export const UPADTE_ADMIN_CUSTOMER = 'update_admin_customer';
export const DELETE_PROFILE_SALES = 'delete_sales_profile';
export const UPADTE_SALES_CUSTOMER = 'update_sales_customer';
export const DELETE_PROFILE_RETAILER = 'delete_retailer_profile';
export const UPADTE_RETAILER_CUSTOMER = 'update_retailer_customer';
export const CHANGE_STATUS_SALES = 'change_status_sales';
export const CHANGE_STATUS_RETAILER = 'change_status_retailer';
export const GET_CSRUSER = 'GET_CSRUSER';
export const GET_CRMUSER = 'GET_CRMUSER';
export const GET_CRMUSER_COUNT = 'GET_CRMUSER_COUNT';
export const CRM_USER_UNLOCK = 'CRM_USER_UNLOCK';

//Tariff Plan
export const GET_TARIFF_PLAN = 'get_tariff_plan';
export const MANAGE_TARRIF_DATA = 'manage_tariff_data';
export const TARRIF_DETAILS_NOTIFICATION = 'tarrif_details_notification';
export const INSERT_TARRIF_DETAILS = 'insert_tariff_details';
export const UPDATE_TARRIF_DETAILS = 'update_tarrif_details';
export const DELETE_TARRIF_DETAILS = 'delete_tarrif_details';

//Price Setting
export const GET_USER_TYPE = 'get_user_type';
export const GET_USER_PRICE = 'get_user_price';
export const MANAGE_WEBUSER_PRICE = 'manage_webuser_price';
export const DELETE_WEBUSER_PRICE = 'delete_webuser_price';

//Guest Admin
export const GET_GUEST_ADMIN = 'get_guest_admin';
export const REGISTER_GUEST_ADMIN = 'register_guest_admin';
export const UPDATE_GUEST_ADMIN = 'update_guest_admin';
export const DELETE_GUEST_ADMIN = 'delete_guest_admin';
export const GUEST_ADMIN_DETAILS_NOTIFICATION =
  'guest_admin_details_notification';

//Tariff  Mapping
export const GET_TARRIF_PRODUCT = 'get_tarrif_product';
export const MAP_TARRIF_DETAILS = 'map_tarrif_details';
export const GET_VOUHCER_PRODUCT = 'get_voucher_product';
export const MAPPING_DETAILS_NOTIFICATION = 'mapping_details_notification';

/* Mail option */

export const GET_INBOX_MAIL = 'get_inbox_mail';
export const GET_SENT_MAIL = 'get_sent_mail';
export const GET_DELETE_MAIL = 'get_delete_mail';
export const GET_EMAIL_IDS = 'get_email_ids';
export const POST_EMAIL = 'post_email';
export const DELETE_EMAIL = 'delete_email';
export const UNDO_EMAIL = 'undo_email';

//change password in profile
export const CHANGE_PROFILE_PASSWORD = 'change_profile_password';
export const RESET_PASSWORD = 'reset_password';
export const RESET_PASSWORD1 = 'reset_password';
export const CHANGE_STATUS = 'change_status';
export const CHANGE_STATUS_1 = 'change_status1';
export const CHANGE_CRM_STATUS = 'change_crm_status';
export const CHANGE_CSA_STATUS = 'change_csa_status';
export const GET_USERS = 'get_users';
export const PROFILE_DETTAILS_NOTIFICATION = 'profile_dettails_notification';
export const UPDATE_CUSTOMER_NOTIFICATION = 'update_customer_notification';
export const TEMP_USER = 'temp_user';
export const INSERT_PROFILE = 'insert_profile';
export const DELETE_PROFILE_DETAILS = 'delete_profile_details';
export const DELETE_PROFILE_DETAILS_1 = 'delete_profile_details1';
export const DELETE_ADMIN_USER = 'delete_admin_user';
export const CHANGE_ADMIN_STATUS = 'change_admin_status';

//  Locked User
export const LOCKED_USERS = 'locked_users';
export const CHANGE_LOCK = 'change_lock';

/* batch summary and pin upload*/

export const GET_MANAGEBATCH_SUMMARY = 'get_managebatch_summary';
export const BATCH_SUMMARY_NOTIFICATION = 'batch_summary_notification';
export const PIN_SUMMARY_NOTIFICATION = 'pin_summary_notification';
export const GET_PIN_STATUS = 'get_pin_status';

//Pin delete Summary

export const GET_PIN_DELETE_SUMMARY = 'GET_PIN_DELETE_SUMMARY';
export const GET_PIN_INDIVIDUAL_SUMMARY = 'GET_PIN_INDIVIDUAL_SUMMARY';

//Voucher search report

export const GET_VOUCHER_SEARCH_REPORT = 'get_voucher_search_report';
export const GET_ADMIN_VOUCHER_SEARCH_REPORT =
  'get_admin_voucher_search_report';
export const GET_RETAILER_VOUCHER_SEARCH_REPORT =
  'get_retailer_voucher_search_report';

// voucher stock report

export const GET_VOUCHER_STOCK_REPORT = 'get_voucher_search_report';
export const GET_VOUCHER_STOCK_REPORT_PER_DATA =
  'get_voucher_search_report_PER_DATA ';

export const GET_SIM_ACTIVATED_LIST_REPORT = ' GET_SIM_ACTIVATED_LIST_REPORT';
export const GET_SIM_ACTIVATED_LIST_PDF = ' GET_SIM_ACTIVATED_LIST_PDF';
export const GET_TOPUP_HISTORY_REPORT = 'GET_TOPUP_HISTORY_REPORT';
export const GET_TOPUP_HISTORY_REPORT_PDF = 'GET_TOPUP_HISTORY_REPORT_PDF';
export const GET_CUSTOMER_BALANCE_REPORT = 'GET_CUSTOMER_BALANCE_REPORT';
export const GET_CUSTOMER_BALANCE_REPORT_PDF =
  'GET_CUSTOMER_BALANCE_REPORT_PDF';
export const GET_CREDIT_BALANCE_REPORT = 'GET_CREDIT_BALANCE_REPORT';
export const GET_CREDIT_BALANCE_REPORT_PDF = 'GET_CREDIT_BALANCE_REPORT_PDF';
export const GET_SIM_ACTIVATED_LIST_SM = ' GET_SIM_ACTIVATED_LIST_SM';

//Sim and voucher sale report
export const GET_SIM_AND_VOUCHER_SALE_REPORT =
  'GET_SIM_AND_VOUCHER_SALE_REPORT';

export const GET_SIM_AND_VOUCHER_SALE_REPORT_PDF =
  'GET_SIM_AND_VOUCHER_SALE_REPORT_PDF';

//Topup Report
export const GET_TOPUP_REPORT = 'GET_TOPUP_REPORT';
export const USAGE_REPORT = 'USAGE_REPORT';
export const VOUCHER_USAGE_REPORT = 'VOUCHER_USAGE_REPORT';

//Topup Report
export const GET_SUBSCRIBER_BASE_REPORT = 'GET_SUBSCRIBER_BASE_REPORT';

// daily sales report admin distributor retailer
export const DAILY_SALES_REPORT_ADMIN = ' DAILY_SALES_REPORT_ADMIN';
export const DAILY_SALES_REPORT_DISTRIBUTOR = ' DAILY_SALES_REPORT_DISTRIBUTOR';
export const DAILY_SALES_REPORT_RETAILER = ' DAILY_SALES_REPORT_RETAILER';
export const DAILY_SALES_REPORT_GET_RETAILER =
  'DAILY_SALES_REPORT_GET_RETAILER';

// Todays Stock
export const SET_DATA = 'SET_DATA ';
export const SIM_DATA = 'SIM_DATA ';
export const REVERSE_PINSTOCK_DATA = 'REVERSE_PINSTOCK_DATA ';
export const MSISDN_DATA = 'MSISDN_DATA';
export const TARIFF_BUNDLE_DATA = 'TARIFF_BUNDLE_DATA';
export const CASH_VOUCHER_DATA = 'CASH_VOUCHER_DATA';
export const DISTRIBUTOR_PINS_DATA = 'DISTRIBUTOR_PINS_DATA';

// Sim Search
export const SEARCH_ICCID_DETAILS = 'SEARCH_ICCID_DETAILS';
export const BEFORE_ACTIVATION_DETAILS = 'BEFORE_ACTIVATION_DETAILS';
export const AFTER_ACTIVATION_DETAILS = 'AFTER_ACTIVATION_DETAILS';

// Sim Preactivation
export const PREACTIVATE_SIM = 'PREACTIVATE_SIM';
export const VERIFY_PREACTIVATION = 'VERIFY_PREACTIVATION';
export const GET_PREACTIVATION_LIST = 'GET_PREACTIVATION_LIST';

// User Notification
export const GET_USER_ALERT = 'get_user_alert';
export const INSERT_USER_ALERT = 'insert_user_alert';
export const UPDATE_USER_ALERT = 'update_user_alert';
export const DELETE_USER_ALERT = 'delete_user_alert';
export const USER_ALERT_NOTIFICATION = 'user_alert_notification';
export const IMAGE_URL_RESPONSE = 'image_url_response';
export const CHANGE_NOTIFICATION_STATUS = 'CHANGE_NOTIFICATION_STATUS';

/* Marquee Option */
export const GET_MARQUEE_LIST = 'get_marquee_list';
export const GET_CARD_MARQUEE_LIST = 'get_card_marquee_list';
export const MARQUEE_DETAILS_NOTIFICATION = 'marquee_details_notification';
export const INSERT_MARQUEE_DETAILS = 'insert_marquee_details';
export const UPDATE_MARQUEE_DETAILS = 'update_marquee_details';
export const DELETE_MARQUEE_DETAILS = 'delete_marquee_details';
// Manage price

export const MANAGE_PRICE = 'MANAGE_PRICE';

export const GET_CHURN_REPORT = 'GET_CHURN_REPORT';

//Shop User Account Admin

export const GET_RETAILER_ADMIN = ' GET_RETAILER_ADMIN';
export const GET_RETAILER_INFORMATION_ADMIN = ' GET_RETAILER_INFORMATION_ADMIN';
export const UPDATE_RETAILER_USER_ADMIN = 'UPDATE_RETAILER_USER_ADMIN';
export const DELETE_USER_ADMIN = 'DELETE_USER_ADMIN';

/* Retailer Swap */

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_RETAILER_LIST = 'GET_RETAILER_LIST';
export const MANAGE_RETAILER_SWAP = 'MANAGE_RETAILER_SWAP';
export const GET_RETAILER_SWAPPED_LIST = 'GET_RETAILER_SWAPPED_LIST';
export const GET_RETAILER_LIST_1 = 'GET_RETAILER_LIST_1';

/* MNP provider list */
export const GET_MNP_PROVIDERS = 'GET_MNP_PROVIDERS';
export const UPDATE_MNP_PROVIDERS = 'UPDATE_MNP_PROVIDERS';
export const INSERT_MNP_PROVIDERS = 'INSERT_MNP_PROVIDERS';
export const DELETE_MNP_PROVIDERS = 'DELETE_MNP_PROVIDERS';

/* Mobile Dashboard Banner Management */
export const INSERT_MOBILE_BANNER = 'insert_mobile_banner';
export const UPDATE_MOBILE_BANNER = 'update_mobile_banner';
export const GET_MOBILE_BANNER = 'get_mobile_banner';
export const DELETE_MOBILE_BANNER = 'delete_mobile_banner';
export const UPDATE_MOBILE_MENU_STATUS = 'update_mobile_menu_status';
export const GET_MOBILE_MENU = 'get_mobile_menu';
